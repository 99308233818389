.popup {
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
  width: 100vw;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: opacity .25s ease;
  transform: translate(0, -100%);
  background: linear-gradient(to bottom, rgba(0,0,0,.75) , rgba(0,0,0,.45));
  
  &.active{
    opacity: 1;
    transform: translate(0, 0);

    .popup-container{
      transform: translate(0, 0);
      transition: transform .25s ease;
    }
  }

  .popup-container {
    height: auto;
    width: 1260px;
    overflow: auto;
    max-width: 90%;
    max-height: 90%;
    position: relative;
    background: white;
    padding: 60px 20px 20px;
    border-radius: $borderRadius;
    transform: translate(0, -100%);
    
    @include respond-to(medium) {
      padding: 60px 
    }
  }

  .popup-close {
    top: 30px;
    border: 0;
    right: 30px;
    width: 20px;
    height: 20px;
    position: absolute;
    background: transparent;

    span { 
      display: none 
    }

    &::before,
    &::after {
      right: 0;
      top: 50%;
      left: 50%;
      content: "";
      width: 22px;
      display: block;
      position: absolute;
      border-radius: $borderRadius;
      border-top: 3px solid black;
      transition: transform .25s ease;
    }

    &::before { 
      transform: translate(-50%, -50%) rotate(45deg) 
    }

    &::after { 
      transform: translate(-50%, -50%) rotate(-45deg) 
    }
  }

  &--video {

    iframe {
      width: 100%;
      height: 100vh;
      display: block;
      margin: 0 auto;
      max-width: 80vw;
      max-height: 70vh;
      margin-bottom: 0px;
    }
  }

  &--image {

    .popup-container { 
      width: auto 
    }

    img {
      margin:0;
      width: 100%;
      height: 100%;
      display: block;
      max-width: 100%;
      max-height: 70vh;
    }
  }
}
