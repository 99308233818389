.videos_list {
  display: flex;
  flex-wrap: wrap;
  margin: 15px -30px;
  width: calc(100% + 60px);
  justify-content: space-between;

  @include respond-to(medium) {
    margin: 30px -30px;
    flex-direction: row;
  }

  &-item {
    width: 100%;
    padding: 0px 30px;

    @include respond-to(medium) { 
      width: 33.33% 
    }

    & + .videos_list-item {
      padding-top: 40px;

      @include respond-to(small) { 
        padding-top: 60px 
      }
  
      @include respond-to(medium) {

        &:nth-child(2), &:nth-child(3) { 
          padding-top: 0px 
        }
      }
    }

    .visual {
      width: 100%;
      overflow: hidden;
      position: relative;
      margin-bottom: 15px;
      border-radius: $borderRadius;

      &:after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
      }

      img {
        margin: 0;
        width: 100%;
        display: block;
        transition: all 0.3s ease-in-out;
      }

      .btn {
        top: 50%;
        left: 50%;
        z-index: 5;
        position: absolute;
        transform: translate(-50%, -50%);
      }

      @include on-event {
        img { 
          transform: scale(1.2) 
        }

        .btn { 
          transform: translate(-50%, -50%) scale(1.2)
        }
      }
    }
  }
}
