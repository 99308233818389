// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//-------- Fonts --------//
$brand-font: 'Poppins', sans-serif;

//-------- Colors --------//
$primary-color: #10378F;
$primary-color-light: #0071BA;
$secondary-color: #31B9EE;
$tertiary-color: #009C73;
$grey-color: #323d3e;

$primary-text-color: #323D3E;


$grey-background: #F5F5F5;
$header-mobile-background: #DDE8EE;
$footer-background: #F5F9FB;
$footer-legal-background: #BCDBE7;

//-------- Colors string --------//
$primaryColorString:  "#{$primary-color}";
$secondaryColorString:  "#{$secondary-color}";

//-------- Border radius --------//
$borderRadius:  5px;

//-------- Font sizes --------//
$medium-txt: 1.125rem; /* 18px */
$regular-txt: 0.875rem; /* 14px */
$small-txt: 0.8125rem; /* 13px */

// Inner container's maximum width
$max-inner-width: 1700px !default;

// Container's maximum width
$max-width: 1920px !default;

/// Breakpoints map
$breakpoints: (
  'small': 768px,
  'medium': 1025px,
  'large': 1250px,
  'huge': 1680px,
) !default;
