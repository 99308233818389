
.collapsing-toggle {

  &.active { 
    display: none !important 
  }
}

.collapsing-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;

  &.videos_list {
    max-height: 90vw;

    @include respond-to(small) { 
      max-height:70vw 
    }

    @include respond-to(medium) { 
      max-height: 30vw 
    }

    @include respond-to(large) { 
      max-height: 360px 
    }
  }

  &.pictures_list {
    max-height: 100vw;

    @include respond-to(medium) { 
      max-height: 24vw 
    }

    @include respond-to(large) { 
      max-height: 18vw 
    }
  }
}