.banner {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  background-color: $primary-color;

  .banner-background {
    width: 100%;
    height:100%;
    overflow: hidden;
    position: absolute;

    &:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      background: linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 75%);
    }

    img {
      margin:0;
      top: 50%;
      left: 50%;
      max-width: none;
      min-width: 100%;
      max-height: 100%;
      border-radius: 0;
      object-fit: cover;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  .banner-content {
    z-index: 5;
    display: flex;
    padding: 20px;
    min-height: 230px;
    padding-top: 30px;
    position: relative;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: center;

    @include respond-to(small) {
      height: 1px; //Fix on IE 
      max-width: 55%;
      min-height: 300px;
      padding: 15px 60px;
    }

    @include respond-to(medium) { 
      min-height: 380px 
    }

    @include respond-to(large) { 
      padding: 15px 120px 
    }

    h1, h2, h3, p { 
      color: white 
    }

    .location {
      text-transform: uppercase;
      
      span { 
        display: block 
      }
    }
  }

  &--large {

    background-color: $primary-color;

    .banner-background {
      height: 45vw;
      position: relative;
      
      @include respond-to(small) {
        top: 0px;
        left: 50%;
        height:100%;
        max-width: 50%;
        position: absolute;
      }

      @include respond-to(medium) {
        top: 0px;
        left: 50%;
        height: 100%;
        max-width: 50%;
        position: absolute;
      }

      &:after {
        content: none;
      }

      img {
        top: auto;
        left: -1px;
        bottom: 0%;
        transform: translate(0%, 0%);
      }
    }

    .banner-content {
      min-height: 0px;

      @include respond-to(small) {
        min-height: 550px;
      }

      .location {
        font-weight: 600;
        padding-top: 15px;
        line-height: 1.5em;
        letter-spacing: 1px;

        @include respond-to(medium) { 
          padding-top: 50px 
        }
      }
    }

    &-3-4 {
      .banner-background {
        left: -35%;
        width: 135%;

        @include respond-to(small) {
          left: 45%;
          max-width: 55%;
        }
  
        @include respond-to(medium) {
          left: 35%;
          max-width: 66%;
        }
      }
    }
  }

  &--speakers {
    display: flex;
    flex-direction: column;

    @include respond-to(medium) {
      flex-direction: row;
      align-items: flex-start;
    }

    .banner-background {
      left: 0%;
      width: 100%;
      height: 40%;
      position: absolute;

      @include respond-to(small) {
        top: 0;
        height: 100%;
        max-width: none;
        display: block;
        position: absolute;
      }

      @include respond-to(large) {
        height: 100%;
        max-width: 50%;
      }

      img {
        top: 0px;
        right: 0px;
        left: auto;
        bottom: 0px;
        height: 100%;
        transform: translate(0%, 0%);
      }
    }

    .banner-content {
      width: 100%;
      height: auto;
      min-height: 0px;
      max-width: 100%;

      @include respond-to(small) {
        padding-top: 30px;
      }

      @include respond-to(medium) {
        max-width: 40%;
        padding-top: 60px;
      }

      @include respond-to(large) {
        max-width: 45%;
      }
    }

    .banner-speakers {
      z-index: 5;
      padding: 30px 15px;
      background-color: $primary-color;

      @include respond-to(medium) {
        width: 60%;
        padding: 60px;
      }

      @include respond-to(large) {
        width: 50%;
        z-index: inherit;
        padding-left: 100px;
      }
    }

    &-3-4 {
      .banner-background {
        width: 200%;
        height: 120vw;

        @include respond-to(large) {
          height: 100%;
          max-width: 85%;
        }
      }
    }
  }
}