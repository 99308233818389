.title {

  span {
    @include respond-to(small) {
      display: block;
    }
  }

  .subtitle {
    display: block;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 10px;
    color: $secondary-color;
    text-transform: uppercase;

    &--large {
      font-size: 1.8em;
    }
  }

  h2 + .subtitle {
    margin-top: -15px;
    margin-bottom: 20px;
  }

  .dot { // If span before the dot
    display: inline-block;
    color: $primary-color-light;
  }

  &--dot {
    h1, h2 {
      position: relative;
      display: inline-block;

      &:after {
        bottom: 0px;
        right: -12px;
        content: ".";
        position: absolute;
        color: $primary-color-light;
      }
    }
  }

  &--center {
    text-align: center;

    .icon {
      display: block;
      padding-bottom: 15px;

      img {
        margin: 0;
        width: 75px;
        height: 75px;
      }
    }
  }

  &--highlight {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    text-align: center;
    align-items: center;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    border-radius: $borderRadius;
    background-color: $grey-background;

    @include respond-to(small) {
      text-align: left;
      padding-top: 40px;
      padding-bottom: 40px;
      flex-direction: row;
    }

    h2 { margin-bottom: 0 }

    .icon {
      padding-bottom: 15px;

      @include respond-to(small) {
        padding-bottom: 0px;
        padding-right: 15px;
      }

      img {
        margin: 0;
        width: 75px;
        height: 75px;
      }
    }
  }
}