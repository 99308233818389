.speaker {
  z-index: 5;
  display: flex;
  position: relative;
  flex-direction: column;

  + .speaker {
    padding-top: 40px;

    @include respond-to(small) { 
      padding-top: 60px 
    }

    @include respond-to(large) { 
      padding-top: 100px 
    }
  }

  @include respond-to(medium) { 
    flex-direction: row 
  }

  .speaker-visual {
    width: 175px;
    margin: auto;
    height: 175px;
    min-width: 175px;
    max-width: 175px;

    @include respond-to(small) {
      width: 275px;
      height: 275px;
      margin-top: 0px;
      min-width: 275px;
      max-width: 275px;
    }

    img {
      margin: 0;
      border-radius: 50%;
    }
  }

  .speaker-info {
    padding-top: 30px;

    @include respond-to(medium) {
      padding-top: 0px;
      padding-left: 50px;
    }
  }

  &--reverse {

    @include respond-to(medium) { 
      flex-direction: row-reverse 
    }

    .speaker-info {

      @include respond-to(medium) {
        padding-left: 0px;
        padding-right: 50px;
      }
    }
  }

  &--tile {
    color: white;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @include respond-to(medium) {
      max-width: none;
      flex-direction: row;
    }

    .speaker-visual {
      width: 150px;
      height: 150px;
      min-width: 150px;
      max-width: 150px;
    }

    .speaker-info {
      flex: 1;
      padding-top: 15px;
      padding-left: 0px;

      @include respond-to(medium) {
        padding-top: 0px;
        padding-left: 30px;
      }

      h5 { 
        color: $secondary-color 
      }

      p { 
        color: white 
      }
    }
  }
}
