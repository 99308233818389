// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
  margin-top: 30px;
  background: $footer-background;

  @include respond-to(medium) { margin-top: 80px }

  > .container {
    display: flex;
    padding-top: 30px;
    flex-flow: row wrap;
    padding-bottom: 15px;
    align-items: flex-start;

    @include respond-to(medium) {
      padding-top: 80px;
      padding-bottom: 80px;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
    }
  }


  .footer-logo {
    flex-basis: 100%;
    padding-bottom: 30px;

    @include respond-to(medium) {
      flex-basis: 35%;
      padding-bottom: 0px;
    }

    img {
      margin: 0;
      width: 200px;
      max-height: 70px;
      border-radius: 0px;

      @include respond-to(medium) {
        width: 250px
      }
    }
  }

  .footer-contact {
    flex-basis: 60%;

    @include respond-to(small) {
      flex-basis:33.33%
    }

    @include respond-to(medium) {
      flex-basis: inherit
    }

    a {
      display: block;
      font-weight: 400;
      color: $primary-text-color;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .footer-social {
    flex-basis: 40%;

    @include respond-to(small) {
      flex-basis:33.33%
    }

    @include respond-to(medium) {
      flex-basis: inherit
    }

    ul {
      display: flex;
      margin-top: 0px;

      li {
        padding-right: 20px;

        img {
          margin: 0;
          width: 23px;
          height: 23px;
          border-radius: 0;
        }
      }
    }
  }

  .footer-newsletter {
    flex-basis: 100%;
    padding-top: 30px;

    @include respond-to(small) {
      padding-top: 0px;
      flex-basis:33.33%;
    }

    @include respond-to(medium) {
      flex-basis: inherit
    }

    .btn {
      margin-top: 15px;

      @include respond-to(medium) {
        margin-top: 30px
      }
    }
  }

  .footer-legal {
    background: $footer-legal-background;


    ul {
      width: 100%;
      display: flex;
      padding: 15px 0px;
      justify-content: flex-end;

      li {
        padding-left: 30px;
        font-size: $small-txt;
      }

      a {
        font-weight: 400;
        font-size: inherit;
        color: $primary-text-color;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
