// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  z-index: 15;
  top: 0px;
  left: 0px;
  width: 100vw;
  position: fixed;
  background: white;
  padding: 20px 0;
  font-size: $medium-txt;
  color: $primary-text-color;

  + main {
    padding-top: 60px;

    @include respond-to(large) {
      padding-top: 55px;
    }
  }

  > .container {
    display: flex;
    margin: 0px auto;
    align-items: center;
    justify-content: space-between;

    @include respond-to(medium) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include respond-to(large) {
      flex-direction: row;
    }
  }

  .header-logo {
    z-index: 20;
    display: flex;

    a { line-height: 0 }

    img {
      margin: 0;
      min-width: 150px;
      max-height: 35px;
      border-radius: 0px;
      transition: all 0.5s ease-in-out;

      @include respond-to(large) {
        min-width: 170px;
        max-height: 70px;
      }
    }
  }

  .header-toggle_nav {
    z-index: 20;
    width: 22px;
    height: 18px;
    border: none;
    padding: 0 10px;
    background: none;
    position: relative;

    @include respond-to(medium) {
      display: none
    }

    span {
      top: 0px;
      left: 0px;
      opacity: 1;
      width: 22px;
      height: 3px;
      display: block;
      margin-bottom: 5px;
      position: absolute;
      border-radius: $borderRadius;
      background: $primary-text-color;
      transition: all 0.5s ease-in-out;

      &:nth-child(2) {
        top:8px
      }

      &:last-child {
        top:16px;
        margin: 0;
      }
    }
  }

  .is-visible + .header-toggle_nav {
    span {
      top: 9px;
      transform: rotate(45deg);
      transition: all 0.5s ease-in-out;

      &:nth-child(2) {
        opacity:0
      }

      &:last-child {
        transform: rotate(-45deg)
      }
    }
  }
}

