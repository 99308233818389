.header-nav {
  top: 0;
  left: -110vw;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  padding-top: 100px;
  position: absolute;
  flex-flow: column nowrap;
  background-color: white;
  justify-content: flex-start;
  transition: 0.5s ease-in-out;

  @include respond-to(small) {
    align-items: flex-start;
  }

  @include respond-to(medium) {
    width: 100%;
    height: inherit;
    padding: 0px;
    margin-top: 20px;
    position: static;
    margin-left: -15px;
    text-align: center;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  @include respond-to(large) {
    margin-top: 0px;
    justify-content: flex-end;
  }

  &.is-visible {
    top:0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    transition: 0.5s;
    background: $header-mobile-background;
  }

  a:not(.btn) {
    display: flex;
    padding: 10px 0;
    font-weight: 600;
    position: relative;
    align-items: center;
    letter-spacing: 0.5px;
    font-size: $medium-txt;
    color: $primary-text-color;
    transition: all 0.5s ease-in-out;

    &:after {
      width: 18px;
      content: "";
      height: 18px;
      margin-left: 15px;
      display: inline-block;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      transition: all 0.5s ease-in-out;
      background-image: url('../svg/caret.svg');
    }

    @include respond-to(medium) {
      margin: 0;
      padding: 0 15px;
      font-weight: 400;
      font-size: $small-txt;

      @include on-event {

        &::after {
          width: 25px;
        }
      }

      &::after {
        left: 15px;
        width: 0px;
        height: 2px;
        bottom: -5px;
        margin-left: 0px;
        position: absolute;
        background: $primary-text-color;
        transition: width 0.3s ease-in-out;
      }
    }

    &.active {
      &::after {
        width: 25px;
      }
    }
  }

  .btn {
    margin-top: 20px;
    padding: 15px 30px;
    text-align: center;

    @include respond-to(medium) {
      margin-top: 0px;
      margin-left: 60px;
    }

    @include respond-to(large) {
      margin-left: 30px;
    }
  }
}
