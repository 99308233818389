// -----------------------------------------------------------------------------
// This file contains basic typographic styles.
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
  line-height: 1.5em;
  color: $primary-text-color;

  @include respond-to(medium) {
    span { display: block }
  }
}

h1 {
  line-height: 1.2em;
  margin-bottom: 20px;
  font-size: 2.1875rem; //35px

  @include respond-to(small) {
    font-size: 2.813rem; //45px
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5625rem; //25px

  @include respond-to(small) {
    font-size: 2.1875rem; //35px
  }
}

h3 {
  margin-bottom: 15px;
  font-size: 1.125rem; //18px

  @include respond-to(small) {
    font-size: 1.5625rem; //25px
  }
}

h4 {
  margin-bottom: 15px;
  font-size: 1rem; //16px
  text-transform: uppercase;

  @include respond-to(small) {
    font-size: 1.125rem; //18px
  }
}

h5 {
  font-size: 0.9375rem; //15px
  margin-bottom: 10px;

  @include respond-to(small) {
    font-size: 1rem; //16px
  }
}

h6 {
  font-size: 0.875rem; //14px
  margin-bottom: 10px;

  @include respond-to(small) {
    font-size: 0.875rem; //14px
  }
}

p,
a,
li {
  font-weight: 400;
  line-height: 2em;
  text-decoration: none;
  font-size: $regular-txt;
  color: $primary-text-color;
}

a {
  font-weight: 600;
  color: $secondary-color;
}

p + h4, 
p + h5, 
ul + h5 {
  margin-top: 20px;

  @include respond-to(medium) {
    margin-top: 40px;
  }
}