.actions {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: flex-start;

  &--center { align-items: center }

  &--right { align-items: flex-end }

  a + a {
    margin-top: 15px;
  }
}