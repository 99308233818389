.video {
  display: block;
  position: relative;

  .title {
    left: 15px;
    bottom: 30px;
    position: absolute;
    max-width: calc(100% - 30px);

    @include respond-to(small) {
      left: 30px;
      bottom: 30px;
      max-width: calc(100% - 60px);
    }

    @include respond-to(medium) {
      left: 80px;
      bottom: 80px;
      max-width: calc(100% - 180px);
    }

    h3 {
      color: white;
    }
  }
}
