$arrowWhiteColor: "%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='m 10.205952,1.8692729 1.187653,-1.18905631 a 1.2831138,1.2831138 0 0 1 1.816574,0 L 23.623897,11.089723 a 1.2831138,1.2831138 0 0 1 0,1.815171 L 13.210179,23.318612 a 1.2803061,1.2803061 0 0 1 -1.816574,0 l -1.189057,-1.189056 a 1.2873254,1.2873254 0 0 1 0.02106,-1.837632 l 6.456279,-6.148839 H 1.284521 A 1.2831138,1.2831138 0 0 1 3.2609376e-6,12.857163 V 11.143069 C 3.2609376e-6,10.429916 0.57277176,9.8571477 1.2859248,9.8571477 H 16.680483 L 10.225606,3.7083091 a 1.2774985,1.2774985 0 0 1 -0.02106,-1.8390362 z'/%3E%3C/svg%3E";
$arrowSecondaryColor: "%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{str-replace($secondaryColorString, '#', '%23')}' d='m 10.205952,1.8692729 1.187653,-1.18905631 a 1.2831138,1.2831138 0 0 1 1.816574,0 L 23.623897,11.089723 a 1.2831138,1.2831138 0 0 1 0,1.815171 L 13.210179,23.318612 a 1.2803061,1.2803061 0 0 1 -1.816574,0 l -1.189057,-1.189056 a 1.2873254,1.2873254 0 0 1 0.02106,-1.837632 l 6.456279,-6.148839 H 1.284521 A 1.2831138,1.2831138 0 0 1 3.2609376e-6,12.857163 V 11.143069 C 3.2609376e-6,10.429916 0.57277176,9.8571477 1.2859248,9.8571477 H 16.680483 L 10.225606,3.7083091 a 1.2774985,1.2774985 0 0 1 -0.02106,-1.8390362 z'/%3E%3C/svg%3E";
$arrowPrimaryColor: "%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{str-replace($primaryColorString, '#', '%23')}' d='m 10.205952,1.8692729 1.187653,-1.18905631 a 1.2831138,1.2831138 0 0 1 1.816574,0 L 23.623897,11.089723 a 1.2831138,1.2831138 0 0 1 0,1.815171 L 13.210179,23.318612 a 1.2803061,1.2803061 0 0 1 -1.816574,0 l -1.189057,-1.189056 a 1.2873254,1.2873254 0 0 1 0.02106,-1.837632 l 6.456279,-6.148839 H 1.284521 A 1.2831138,1.2831138 0 0 1 3.2609376e-6,12.857163 V 11.143069 C 3.2609376e-6,10.429916 0.57277176,9.8571477 1.2859248,9.8571477 H 16.680483 L 10.225606,3.7083091 a 1.2774985,1.2774985 0 0 1 -0.02106,-1.8390362 z'/%3E%3C/svg%3E";


.btn {
  border: 0;
  z-index: 5;
  color: white;
  line-height: 1;
  cursor: pointer;
  font-weight: 600;
  overflow: hidden;
  padding: 15px 20px;
  border-radius: 30px;
  position: relative;
  text-decoration: none;
  display: inline-block;
  font-size: $small-txt;
  letter-spacing: 0.5px;
  transition: all 0.5s ease-in-out;
  background-color:  $secondary-color;

  @include on-event{
    background-color: $primary-color;
  }

  &--secondary {

    @include on-event{
      color: $primary-color;
      background-color: white;
    }

    &.btn--arrow {

      @include on-event{
        &:after {
          background-image: url("data:image/svg+xml, #{$arrowPrimaryColor}");
        }
      }
    }
  }
  
  &--tertiary {
    background-color: $tertiary-color;

    @include on-event{
      background-color: $grey-color;
    }
  }

  &--arrow {
    display: flex;
    align-items: center;

    &:after {
      width: 12px;
      height: 12px;
      content: "";
      display: block;
      margin-left: 20px;
      background-size: 12px 12px;
      transition: all 0.5s ease-in-out;
      background-image: url("data:image/svg+xml, #{$arrowWhiteColor}");
    }

    @include on-event{
      &:after { transform: translate(5px, 0)}
    }
  }

  &--newsletter {
    display: flex;
    align-items: center;

    &:before {
      width: 16px;
      height: 16px;
      content: "";
      display: block;
      margin-right: 20px;
      background-size: 16px 16px;
      background-image: url('../svg/newsletter.svg');
    }
  }

  &--play {
    width: 55px;
    height: 55px;
    padding: 0px;
    display: flex;
    font-size: 0px;
    align-items: center;
    justify-content: center;
    background-color: $secondary-color;

    @include on-event { transform: scale(1.2) }

    &:before {
      width: 25px;
      height: 25px;
      content: "";
      display: block;
      background-size: 25px 25px;
      background-image: url('../svg/play.svg');
    }
  }

  &--large {
    font-size: 1.5em;
    padding: 25px 30px;
    align-items: center;
    display: inline-flex;
    color: $primary-text-color;
    border-radius: $borderRadius;
    background-color: $grey-background;

    @include respond-to(medium) { padding: 25px 50px }

    &:after {
      width: 45px;
      content: "";
      height: 45px;
      display: block;
      min-width: 45px;
      max-width: 45px;
      margin-left: 20px;
      border-radius: 50%;
      background-size: 15px 15px;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.5s ease-in-out;
      background-color: $secondary-color;
      background-image: url('../svg/arrow.svg');
    }

    @include on-event{
      background-color: #F5F5F5;

      &:after {
        background-color: $primary-color;
      }
    }
  }
}
