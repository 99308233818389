section {
  padding-top: 15px;
  padding-bottom: 15px;

  @include respond-to(medium) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &:first-of-type {
    padding-top: 0px;
    padding-bottom: 0px;
    
    @include respond-to(medium) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &.section--highlight {
    border-radius: $borderRadius;
    background-color: $grey-background;

    @include respond-to(medium) {
      margin-left: 80px;
      margin-right: 80px;
    }
  }
}
