// -----------------------------------------------------------------------------
// This file contains all styles related to the layout of the site/application.
// -----------------------------------------------------------------------------
html {
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

body {
  margin: 0 auto;
  font-size: 0.8em;
  font-weight: 400;
  min-height: 100vh;
  overflow-x: hidden;
  background: white;
  font-family: $brand-font;
}

main {
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  max-width: $max-width;
}

.container {
  clear: both;
  margin: 15px auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: $max-inner-width;

  @include respond-to(medium) {
    margin: 0px auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include respond-to(large) {
    padding-left: 180px;
    padding-right: 180px;
  }

  + .container:not(.container--no-paddingTop) {

    @include respond-to(small) {
      padding-top: 40px;
    }

    @include respond-to(medium) {
      padding-top: 80px;
    }
  }

  &--full {

    @include respond-to(medium) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include respond-to(large) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  &--small {

    @include respond-to(medium) {
      padding-left: 175px;
      padding-right: 175px;
    }

    @include respond-to(large) {
      padding-left: 300px;
      padding-right: 300px;
    }
  }

  &--xsmall {

    @include respond-to(medium) {
      width: 80%;
    }

    @include respond-to(large) {
      width: 60%;
    }
  }

  &--cols {

    @include respond-to(small) {
      display: flex;
      margin: 0 auto;
      flex-wrap: wrap;
      flex-direction: row;


      > div, > img, > p {
        width: calc(50% - 30px);

        &:nth-child(odd) {
          margin-right: 30px;
        }

        &:nth-child(even) {
          margin-left: 30px;
        }
      }

    }

    &3-4 {

      @include respond-to(small) {
  
        > div {
          &:nth-child(odd) {
            width: calc(35% - 30px);
          }

          &:nth-child(even) {
            width: calc(65% - 30px);
          }
        }
      }
    }

    &--center {
      @include respond-to(medium) {
        align-items: center;
      }
    }

  }

  .section--planning & {
    + .container {
      margin-top: 40px;

      @include respond-to(medium) {
        margin-top: 20px;
      }
    }
  }
}

img {
  max-width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: $borderRadius;

  @include respond-to(medium) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &.no-margin {
    margin-top: 0px;

    @include respond-to(medium) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

iframe {
  max-width: 100%;
  margin-bottom: 15px;
}
