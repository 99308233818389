.pictures_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -20px;
  width: calc(100% + 40px);
  justify-content: space-between;

  @include respond-to(medium) {
    margin: 30px -30px;
    flex-direction: row;
    width: calc(100% + 60px);
  }

  &-item {
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    border-radius: 10px;
    margin-bottom: 30px;
    width: calc(50% - 30px);
    padding-bottom: calc(50% - 30px);

    @include respond-to(medium) {
      width: calc(25% - 30px);
      padding-bottom: calc(25% - 30px);
    }

    img {
      top: 50%;
      left: 50%;
      margin: 0;
      max-width: none;
      min-width: 100%;
      max-height: 100%;
      border-radius: 0;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}
